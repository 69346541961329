<template>
  <div>
    <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
      <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
    </div>

    <div class="table-responsive-md" v-else>
      <table class="table" style="width: 100%" v-if="partnerSubscriptions.length > 0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Plan</th>
            <th>Period</th>
            <th>Date</th>
            <th>Expiry Date</th>

            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="subscription in partnerSubscriptions" :key="subscription.id">
            <td>
              <a href="#" class="link-hover-dark-blue">{{ subscription.id }}</a>
            </td>
            <td>{{ subscription.subscription.title }}</td>
            <td>
              <strong>{{ subscription.subscriptionQuantity }}
                {{ subscription.subscriptionPeriod }}</strong>
            </td>
            <td>{{ subscription.dateSubscribed }}</td>
            <td>{{ subscription.dateExpired }}</td>
            <td>
              <span class="status paid text-primary"
                v-if="subscription.status == 'Active'">{{ subscription.status }}</span>
              <span class="status paid text-dark" v-else>{{subscription.status}}</span>
            </td>
            <td v-if="subscription.subscription.isDefault">
                  <!-- <button v-if="subscription.status == 'Active'" @click="cancelSubscription(subscription)"
                    class="btn btn-darker-light btn-sm">
                    Cancel -->
                  <!-- </button> -->
                  <button class="btn btn-darker-light btn-sm" v-tooltip="'Free subscription is no longer activated or renewed'">
                    <i class="fal fa-info"></i>
                  </button>
            </td>
             <td v-else>
                 <!-- <button v-if="subscription.status == 'Active'" @click="cancelSubscription(subscription)"
                class="btn btn-darker-light btn-sm">
                Cancel
              </button> -->

                <button v-if="subscription.status == 'Inactive'" @click="reActiveSubscription(subscription)"
                class="btn btn-primary btn-sm">
                Re-active
              </button>

              <button v-if="subscription.status == 'Canceled'" @click="reActiveSubscription(subscription)"
                class="btn btn-info btn-sm">
                Re-active
              </button>
              <!-- <button
                  class="btn btn-primary btn-sm"
                  v-if="subscription.status == 'Expired' && !subscription.subscription.isDefault"
                >
                  Renew
                </button> -->
             </td>
          </tr>
        </tbody>
      </table>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

      <div class="photo-gallery d-flex flex-wrap justify-content-between">
        <NoResult v-if="partnerSubscriptions.length == 0" title="Ohh! Subscription not found"
          message="It seems we can’t find what you’re looking for."></NoResult>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import {
    MoonLoader
  } from "@saeris/vue-spinners";
  import NoResult from "../Common/Message/NoResult";
  import ConfirmDialogue from '../Common/Modal/ConfirmDialogue'
  export default {
    name: "BusinessSubscription",
    props: ["partnerId", "refresh"],
    data: function () {
      return {
        isLoading: false,
      };
    },
    components: {
      MoonLoader,
      ConfirmDialogue,
      NoResult,
    },
    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
      this.getRefresh();
    },
    computed: {
      ...mapGetters("subscription", ["partnerSubscriptions"]),
      ...mapGetters(["errors", "isSuccess"]),
    },
    watch: {
      refresh: {
        handler: 'getRefresh'
      }
    },

    methods: {
      ...mapActions("subscription", ["getPartnerSubscriptions", "sendUpdateSubscriptionRequest"]),
      async getRefresh() {
        this.isLoading = true;
        await this.getPartnerSubscriptions(this.partnerId).then(
          () => (this.isLoading = false)
        );
      },
      cancelSubscription: async function (subscription) {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Cancel Subscription',
          message: 'Are you sure you want to cancel this subscription',
          okButton: 'Cancel',
        })
        if (ok) {
          subscription.status = "Canceled";
          this.isLoading = true;
          this.sendUpdateSubscriptionRequest(subscription).then(() => {
            this.isLoading = false
            if (this.isSuccess) {
                this.getRefresh();
              this.$notify({
                group: "app",
                type: "success",
                title: "Done",
                text: "Subscription canceled successfully",
                duration: 10000,
                speed: 1000,
              });
            };
          })
        }
      },

      reActiveSubscription: async function (subscription) {

        subscription.status = "Active";
        this.isLoading = true;
        this.sendUpdateSubscriptionRequest(subscription).then(() => {
          this.isLoading = false
          if (this.isSuccess) {
             this.getRefresh();
            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Subscription actived successfully",
              duration: 10000,
              speed: 1000,
            });
          };
        })

      },
    },
  };
</script>