<template>
  <!-- #site-wrapper start -->
  <div
    v-if="user"
    id="site-wrapper"
    class="site-wrapper panel page-invoice-listing"
  >
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">
            <div class="page-content-wrapper d-flex flex-column h-100">
              <h1 class="font-size-h4 mb-4 font-weight-normal">
                My Subscription
              </h1>
              <div class="page-content">
                <div class="border-bottom mb-3 pb-7 lh-1">
                  <div class="font-size-lg text-dark font-weight-semibold">
                    <div class="float-left">Subscription Listings</div>
                    <div class="float-right">
                      <button @click="createNewSubscription()" class="btn btn-darker-light font-size-sm btn-sm"> <i class="fal fa-plus"></i> Add new</button>
                    </div>
                  </div>
                </div>
                <BusinessSubscription v-bind:partner-id="this.$route.params.partnerId" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
     <FooterBar />
    <!-- #wrapper-content end -->
  </div>

  <div v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "../Layouts/NavBar";
import LeftSideBar from "../Layouts/LeftSideBar";
import FooterBar from "../Layouts/FooterBar";
import LoadingScreen from "../Layouts/LoadingScreen";
import BusinessSubscription from "../MySubscription/BusinessSubscription";
export default {
  name: "MySubscription",

  components: {
    NavBar,
    LeftSideBar,
    FooterBar,
    BusinessSubscription,
    LoadingScreen,
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  mounted() {
  },
  methods: {
    ...mapActions("auth"),
    createNewSubscription(){
       this.$router.push({
        name: "CreateNewSubscription",
        params: {
          partnerId: this.$route.params.partnerId,
        },
      }).catch(() => {});
    }
  },
};
</script>