<template>
  <!-- #site-wrapper start -->
  <div
    v-if="user"
    id="site-wrapper"
    class="site-wrapper panel page-invoice-listing"
  >
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">
            <div class="page-content-wrapper d-flex flex-column h-100">
              <h1 class="font-size-h4 mb-4 font-weight-normal">
                My Contacts
              </h1>
              <div class="page-content">
                <!-- <div class="border-bottom mb-3 pb-7 lh-1">
                  <div class="font-size-lg text-dark font-weight-semibold">
                    <div class="float-left">Subscription Listings</div>
                    <div class="float-right">
                      <button @click="createNewSubscription()" class="btn btn-darker-light font-size-sm btn-sm"> <i class="fal fa-plus"></i> Add new</button>
                    </div>
                  </div>
                </div> -->
               <div v-if="user" class="blog-listing blog-listing-grid">
      <ul
        class="
          nav nav-pills
          tab-style-01
          text-capitalize
          font-size-lg
          text-dark
        "
        role="tablist"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            id="MyContact-tab"
            data-toggle="tab"
            href="#MyContact"
            role="tab"
            aria-controls="MyContact"
            aria-selected="true"
            >My Contacts</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="IncomingContactsRequests-tab"
            data-toggle="tab"
            href="#IncomingContactsRequests"
            role="tab"
            aria-controls="IncomingContactsRequests"
            aria-selected="false"
          >
            Incoming Contacts Requests

          </a>
        </li>
    
        <li class="nav-item">
          <a
            class="nav-link"
            id="MySuggested-tab"
            data-toggle="tab"
            href="#MySuggested"
            role="tab"
            aria-controls="MySuggested"
            aria-selected="false"
            >My Suggested
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane fade active show"
          id="MyContact"
          role="tabpanel"
          aria-labelledby="MyContact"
        >
        MyContact
        </div>

        <div
          class="tab-pane fade"
          id="IncomingContactsRequests"
          role="tabpanel"
          aria-labelledby="IncomingContactsRequests"
        >
         IncomingContactsRequests
        </div>
        <div
          class="tab-pane fade"
          id="MySuggested"
          role="tabpanel"
          aria-labelledby="MySuggested"
        >
         MySuggested
        </div>
      
      </div>
    </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
     <FooterBar />
    <!-- #wrapper-content end -->
  </div>

  <div v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "../Layouts/NavBar";
import LeftSideBar from "../Layouts/LeftSideBar";
import FooterBar from "../Layouts/FooterBar";
import LoadingScreen from "../Layouts/LoadingScreen";
export default {
  name: "MyContact",

  components: {
    NavBar,
    LeftSideBar,
    FooterBar,
    LoadingScreen,
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  mounted() {
  },
  methods: {
    ...mapActions("auth")
  },
};
</script>